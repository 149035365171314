@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.btnCustom.ant-btn {
  width: 100%;
  font-family: $baseFont;
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 14px;
  @include fluid-type(320px, 1920px, 12px, 14px);
  color: $btnBaseColor;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.25s;
  border: 0px;
  box-shadow: none;

  &:hover {
    opacity: 0.9;
    color: $btnBaseColor;
  }

  &.lessPadding {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &.garyBtn {
    @include theme() {
      background-color: theme-get("grayBtn");
      color: $white;
    }
  }

  &.lightgray {
    background-color: #e1e5ea;
    color: #68696b;
    border-radius: 10px;
    font-weight: 700 !important;
    text-transform: uppercase;
    max-width: 97px;
    width: 100%;
    height: 25px !important;
  }

  &.garyBtns {
    @include theme() {
      background-color: #9fa4aa;
      max-width: 90px;
      width: 100%;
      border-radius: 3px;
      height: 31px;
      text-transform: capitalize;
      color: $white;
    }
  }

  &.black {
    background-color: $black;
    color: $btnTextLight;
  }

  &.small {
    background-color: #1677ff;
    border: 1px solid #1677ff;
    color: $white;
    font-weight: 500;
    font-size: 12px;
    max-width: 180px;
    width: 100%;
    padding: 0px 12px;
    height: 48px;
    text-transform: uppercase;
    border-radius: 5px;
    line-height: 48px;
    span {
      color: $white;
      font-weight: 400;
    }
  }

  &.white {
    background-color: $white;
    color: $black1 !important;
    border-color: $bordercolorcard;
    font-weight: 400;
    font-size: 14px;
    min-width: 180px;
    padding: 22px 20px;
    height: 48px;
    border-radius: 5px;
    line-height: normal;

    &:hover {
      color: $white !important;
      background-color: $textcolor;
      border-color: 2px solid $textcolor;
    }
  }

  &.lytgaryBtn {
    background-color: $btnTextLight;
    color: $btnBaseColor;
  }

  &.orderBtn {
    @include theme() {
      background-color: theme-get("OrderBtn");
      color: theme-get("OrderBtnColor");
    }
  }

  &.lightBtn {
    @include theme() {
      background-color: theme-get("lightBtn");
    }

    color: $btnBaseColor;
  }

  &.yellowBtn {
    background-color: #1677ff;
    color: $white;

    // @include theme() {
    //     background-color: theme-get("yellowBtn");
    //     color: $btnBaseColor;
    //   }
    span {
      color: #fff;
    }
  }

  .leftIcon {
    // width: 18px;
    // height: 18px;
    // margin-right: 5px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  .rightIcon {
    // width: 18px;
    // height: 18px;
    // margin-left: 5px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  .onlyIcon {
    // width: 18px;
    // height: 18px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // margin:0 -8px;
  }

  &.medium {
    height: 29px;
  }

  &.large {
    height: 45px;
  }
}
