@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";

.overviewdata {
  &__ApiKey {
    min-height: 100%;

    .textareasection {
      padding: 22px;
      position: relative;
      // min-height: 306px;

      .regeneratekey {
        background-color: transparent;
        width: 100%;
        border: 1px solid $bordercolorcard;
        text-align: center;
        padding: 10px 15px;
        border-radius: 5px;
        margin-top: 25px;
        cursor: pointer;

        &:hover {
          color: #fff;
          background-color: #6c757d;
          border-color: #6c757d;
        }
      }

      .textarea {
        // height: 185px;
        width: 100%;
        position: relative;
        border: 1px solid $bordercolorcard;
        border-radius: 5px;
        text-align: center;
        color: #b1c7df;

        // @media (max-width: 1550px) {
        //   height: 386px;
        // }

        // @media (max-width: 1199px) {
        //   height: 185px;
        // }
      }

      .staradd {
        word-break: break-all;
        text-align: center;
        padding: 20px;
        line-height: 40px;

        @media (max-width: 479px) {
          padding: 10px;
        }

        p {
          @include fluid-type(320px, 1920px, 20px, 27px);
        }
      }

      .star {
        padding: 20px;
        line-height: 40px;

        @media (max-width: 479px) {
          padding: 10px;
        }

        p {
          @include fluid-type(320px, 1920px, 16px, 24px);
          word-break: break-all;
        }
      }

      .copyKeybtn {
        background: #4da1ff;
        border: 1px solid #4da1ff;
        font-weight: 500;
        color: $white;
        padding: 0 15px;
        text-transform: uppercase;
        border-radius: 5px;
        line-height: 3rem;
        top: 30%;
        cursor: pointer;
        position: absolute;
        font-weight: 400;
        color: #fff;

        &:hover {
          background-color: #0069d9;
        }
        span {
          color: #fff;
        }
      }
    }
  }

  .CircularProgressbar {
    // display: inline-block;
    // max-width: 150px;
  }

  &__right {
    min-height: 100%;

    // max-width: 1043px;
    // width: 100%;

    .greytext {
      text-align: right;
      color: $textcolor;
      @include fluid-type(320px, 1920px, 12px, 14px);
      padding-right: 20px;
      padding-bottom: 20px;
    }

    width: 100%;

    .circularprogress {
      display: flex;
      padding: 30px;
      gap: 20px;

      @media (max-width: 1550px) {
        flex-wrap: wrap;
        justify-content: center;
      }

      &__left {
        display: flex;
        border-right: 1px solid $bordercolorcard;
        gap: 45px;
        padding-right: 35px;

        @media (max-width: 1550px) {
          border-right: none;
          padding-left: 0;
          padding-right: 0;
        }
      }

      &__right {
        display: flex;
        gap: 45px;
        padding-left: 20px;

        @media (max-width: 1550px) {
          padding-left: 0;
        }
      }
    }
  }

  .timer {
    text-align: center;
  }
}

.tablesection {
  margin: 30px 0;

  .headingsection {
    padding: 13px 30px;
    border-bottom: 1px solid #e1e5ea;
    background: $white;
    color: #17181b;
    @include fluid-type(320px, 1920px, 12px, 16px);
    margin-bottom: 0;
    font-weight: normal;
  }
}

.apirequest {
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
  }

  span {
    color: gray;
  }

  .danger {
    color: rgb(216, 115, 115);
    margin-right: 2px !important;
  }

  .hashapi {
    padding-right: 14px;
  }
}

// .ant-table {
//   tr {
//     &:last-child {
//       td {
//         border-bottom: 0 !important;
//         padding: 20px 30px !important;
//       }
//     }
//   }
// }
