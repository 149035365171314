.captcha {
    margin-bottom: 25px;
}

.annoucements_block {
    flex: 50%;
}

// .ant-row {
//     padding: 20px;
// }
// .inputLayout {
//     @media (max-width:1340px) {
//         width: 48%;
//     }

// }