@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";

.adminControls {
  // display: flex;
  // gap: 20px;
  // flex-direction: column;

  .account-detail {
    min-height: 100%;
  }

  &_details {
    display: flex;
    gap: 30px;
    width: 100%;

    @media (max-width:1340px) {
      flex-wrap: wrap;
    }

    .ant-row {
      width: 100%;
    }

  }

  &_block {
    h2 {
      @include theme() {
        color: theme-get("headerDropdownTxt") !important;
      }

      @include fluid-type(320px, 1920px, 16px, 24px);
      font-weight: 600;
      margin-bottom: 20px;
    }

    button {
      max-width: 200px;
      width: 100%;
      display: block;
      margin-top: 20px;
    }
  }
}

.divider {
  @include theme() {
    border-color: theme-get("sidebarRightBorder") !important;
  }
}