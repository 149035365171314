@import '../../Assets/Styles/_vars.scss';
@import "../../Assets/Styles/_mixins.scss";

.cardInner {
    // background-color: $white;
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    border: none;
    width: 100%;

    p {

        @include fluid-type(320px, 1920px, 12px, 16px);
        margin-bottom: 0;

        img {
            vertical-align: unset;
            padding: 0 4px;
        }

        // font-weight: 400px;
    }

    .heading {
        display: flex !important;
        align-items: center;
        justify-content: space-between !important;
        border-bottom: 1px solid $bordercolorcard;
        background: none;
        color: $black1;
        padding: 13.5px 20px;

        button {
            background: $bordercolorcard;
            color: $graybutton;
            text-transform: uppercase;
            border-radius: 10px;
            height: 25px;
            font-weight: 700;
            @include fluid-type(320px, 1920px, 12px, 14px);
        }

    }

}