@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";

.notification {

    .notificationinnerText {
        padding-bottom: 15px;

        p {
            padding: 15px 20px;
            color: $notiText;
            @include fluid-type(320px, 1920px, 14px, 16px);

        }
    }

    .notificationsetting {
        margin-top: 20px;

        .checkboxes {
            padding: 0px 20px 15px 20px;
            display: flex;
            flex-direction: column;
            gap: 5px;

            span {
                @include fluid-type(320px, 1920px, 14px, 16px);
                color: $notiText;
            }
        }
    }

    .ant-btn>span {
        font-size: 13px !important;
        font-weight: normal !important;
    }

}

.notificationtable {

    .notificationitems {
        max-width: 1500px;
        width: 100%;

        span {
            word-break: break-word !important;
            white-space: normal;
            color: gray;
        }
    }

    tbody>tr>td {
        &:first-child {
            width: 85% !important;
        }
    }
}