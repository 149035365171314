@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";
.inputLayout {
  position: relative;

  .inputCustum {
    border: 1px solid;
    border-radius: 5px;
    opacity: 1;
    width: 100%;
    height: 45px;
    box-shadow: none;
    position: relative !important;
    background-color: transparent;
    font-weight: 400;
    @include theme() {
      border-color: theme-get("inputborder");
    }
    @include theme() {
      color: theme-get("whiteblackBase");
    }

    &:focus {
      box-shadow: none;
    }
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      border-color: $CommonButton !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
  .inputInnerTxt {
    padding-right: 100px;
  }
  .eyeIcon {
    // &:hover {
    //   @include theme() {
    //     border-color: theme-get("inputborder") !important;
    //   }
    // }
    input {
      background: transparent;
      font-weight: 400;
      @include theme() {
        color: theme-get("whiteblackBase");
      }
    }
    svg path {
      fill: #8d8d8d;
    }
  }
  .innerTxt {
    position: absolute;
    right: 16px;
    top: 55%;
    // transform: translateY(-50%);
    color: $CommonButton;
    cursor: pointer;
  }
  .inputInnerLargeTxt {
    padding-right: 185px;
  }
  .innerTxtImg {
    position: absolute;
    right: 16px;
    top: 55%;
    // transform: translateY(-50%);
    color: #555863;
    display: flex;
    align-items: center;
    svg {
      margin-left: 10px;
    }
  }

  .inputSearch {
    padding-left: 45px;
  }
  .searchImg {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  .label {
    letter-spacing: 0px;
    @include theme() {
      color: theme-get("titlehead");
    }
    opacity: 1;
    width: 100%;
    text-align: left;
    @include fluid-type(320px, 1920px, 12px, 14px);
    display: flex;
    padding-bottom: 10px;
  }
}
