  .App {
    /* font-family: sans-serif;
    text-align: center;
    box-align: center;
    width: fit-content;
    margin: 100px auto; */
    font-family: sans-serif;
    display: grid;
    gap: 20px;
    width: fit-content;
    box-align: center;
    text-align: center;
    margin: 40px auto;
    grid-template-columns: repeat(4,1fr);
    grid-column-gap:20px
    /* margin-left: 7%; */

  }
  
  * {
    color: #343c45;
    font-size: 16px;
    /* font-family: "Segoe UI"; */
    font-family: "Roboto", sans-serif !important;
    /* font-weight: 600; */
  }
  
  #scale-up-1 {
    transition: 0.5s;
  }
  
  #scale-up-1:hover {
    transform: scale(1.1);
    height: 481px !important;
  }
  
  .module-border-wrap {
    border-image-slice: 1 !important;
    border-width: 0 !important;
    border-top-width: 5px !important;
    border-image-source: linear-gradient(to left, #d96938 40%, #f7a83f 60%) !important;
}
  .heading{
    color: #313c47;
    letter-spacing: -0.2px;
    text-align: center;
    line-height: 70px;
    font-size: 2.7rem;
    font-weight: 400;
  }
  
  /* For full border */
  /* .module-border-wrap {
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
  } */
  .selectedBtn{
    width: 100%;
    border: 1px solid;
    color: black;
    background-color:#fff;
  
    padding: 0rem 0.75rem;
    height: 3rem;
    border-radius: 5px !important;
    border-color:#E1E5EA !important;
    line-height: 3rem !important;
  }
  .selectedBtn:hover{
    background-color:#6c757d;
    color: rgb(255, 255, 255);
  }
