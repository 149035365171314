@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
$baseFont: "Roboto", sans-serif;
$exchangeFont: "Open Sans", sans-serif;
$btnBaseColor: #0b9eda;
$lightBtnAdv: #eaecef;
$lightDarkBtn: #e8e8e8;
$btnTextLight: #e4e4e4;
$CommonButton: #0b9eda;
$hoverBaseColor: #0b9eda;
$grayLightTxt: #848e9c;
$fafbfbcolor:#fafbfb;
$notiText:#989898;
$greenTxt: #0ecb81;
$lightGreenBg: #124234;
$buttonbackground:#4da1ff;
$textcolor:#6c757d;
$yellowDarkBg: #0b9eda;
$redTxt: #f6465d;
$graybutton:#68696b;
$checkboxparagraph: #848e9c;
$titlehead: #1e252d;
$black: #000;
$black1:#17181b;
$bordercolorcard:#e1e5ea;
$white: #fff;
$selectFillBg: #ecedf0;
$staticBorder: #bec3ca;
$kycModalBg: #f5f5f5;
$cameraSectionBorder: #babdc1;
$borderColor: #464e5c;
$xValueColor: #6a4403;
$xValueBorder: #0b9eda;
$traditionalTypes__red: #ea0070;
$traditionalTypes__green: #74a700;
$defeciencyTypes__red: #dd7a2b;
$defeciencyTypes__green: #1f8df9;
$hoveredYellowedTxt: #0b9eda;
$bgMainColor : #0b9eda;
// Dual Theme control mode start
$themes: (
  lightTheme: (
    "securityBtn": #dfe3ec,
    "darkWhite": #000,
    "darkWhiteReverse": #fff,
    "securitypage": #f2f3f5,
    "securitypage1": #1e252d,
    "twofactorAuthentication1": #1e252d,
    "cmnBtnColor": #0b9eda,
    "grayBtn": #0b9eda,
    "grayBtnAdv": #282c34,
    "lightBtn": #e4e4e4,
    "footerTextCopyright": #0b9eda,
    "yellowBtn": #0b9eda,
    "bgSection": #fafafa,
    "inputborder": #bec3ca,
    "paragraph": #848e9c,
    "titlehead": #1e252d,
    "selectBorder": #bec3ca,
    "selectTxtColor": #0b9eda,
    "selectOptionsBG": #fff,
    "selectTxtColorSelected": #f5f5f5,
    "selectHoverableBg": #f5f5f5,
    "whiteblack": #000,
    "headerDropdownBg": #fafafa,
    "headerDropdownTxt": #1e252d,
    "navigationHoverable": #dfe3ec,
    "twofactorAuthentication": #1e252d,
    "sidebarRightBorder": #dcdcdc,
    "sidebarActiveItem": #f2f3f5,

    "whiteblackBase": #1e252d,
    "logbtnhover": #f2f3f5,
    "ProfileSvgBg": #848e9c,
    "processbar": #f1f2f3,
    "ModalColor": #f5f5f5,
    "tableBG": #fafafa,
    "borderExchangeTopbar": #f0f0f0,
    "exchangeBgClassic": #fafafa,
    "buttonTabExc": #fff,
    "orderFormBg": #f2f3f5,
    "exchangeInput": #f2f3f5,
    "sliderTrack": #474d57,
    "sliderHandle": #fff,
    "sliderRail": #dce0e5,
    "sliderBorder": #474d57,
    "sliderPoints": #fff,
    "sliderPointsColor": #707a8a,
    "sliderPointsBorder": #eaecef,
    "referralScreenBar": #f2f3f5,
    "referralScreenText": #1e252d,
    "referralScreebuttonshownhistoryBtn": #f3f3f3,
    "referralTablecolor": #848e9c,
    "progressLine": #fafafa,
    "ApiBtnClr": #dfe3ec,
    "QrBg": #f5f5f5,
    "QrText": #0b9eda,
    "personalTag": #f8de753d,
    "headerBackground": rgb(212, 235, 245),
    "cardBorder": #dcdcdc,
    "headerText": #1e252d,
    "headerInputBorder": #bec3ca,
    "listBorderBottom": #dcdcdc,
    "grayBtnBackground": #dfe3ec,
    "hideBalButton": #fafafa,
     
    "scrollbarTrack": #0000000d,
    "scrollbarThumb": #0000001a,

    "OpenHead": #f2f3f5,
    "OrderBtn": #dfe3ec,
    "OrderBtnColor": #000000,
    "MarketFadeTxt": #1e252d,
    "ResponsiveBordersExchange": #efefef,
    "fiatBtn":#DFE3EC,
    "tabBg":#F2F3F5,
    "bgexportbtn":#0b9eda,
    "darkness":#101010,
    "modalBg" : #fafafa,
  ),
  darkTheme: (
    // "securityBtn": #282c34,
    // "darkWhite": #fff,
    // "darkWhiteReverse": #000,
    // "cmnBtnColor": #2c3037,
    // "grayBtn": #2c3037,
    // "grayBtnAdv": #dfe3ec,
    // "lightBtn": #eaecef,
    // "footerTextCopyright": #eaecef,
    // "yellowBtn": #7D80DF,
    // "bgSection": #181a20,
    // "inputborder": #4e545e,
    // "paragraph": #e4e4e4,
    // "titlehead": #e4e4e4,
    // "selectBorder": #4e545e,
    // "selectTxtColor": #fff,
    // "selectOptionsBG": #181a20,
    // "selectTxtColorSelected": #0b9eda,
    // "selectHoverableBg": #2c3037,
    // "whiteblack": #fff,
    // "whiteblackBase": #fff,
    // "securitypage": #0b9eda,
    // "securitypage1": #f5f5f5,
    // "twofactorAuthentication": #f5f5f5,
    // "headerDropdownBg": #242730,
    // "headerDropdownTxt": #f5f5f5,
    // "navigationHoverable": #2e323a,
    // "sidebarRightBorder": #252930,
    // "sidebarActiveItem": #282c34,
    // "logbtnhover": #2b3139,
    // "ProfileSvgBg": #f5f5f5,
    // "processbar": #272a2e,
    // "ModalColor": #1e2329,
    // "tableBG": #27282e,
    // "borderExchangeTopbar": #848e9c,
    // "exchangeBgClassic": #161a1e,
    // "buttonTabExc": #1e2026,
    // "orderFormBg": #161a1e,
    // "exchangeInput": #2a2d35,
    // "sliderTrack": #b7bdc6,
    // "sliderHandle": #2b3139,
    // "sliderRail": #474d57,
    // "sliderBorder": #f5f5f5,
    // "sliderPoints": #1e2329,
    // "sliderPointsColor": #848e9c,
    // "sliderPointsBorder": #474d57,
    // "referralScreenBar": #0b9eda,
    // "referralScreenText": #f5f5f5,
    // "referralScreenhistoryBtn": #282c34,
    // "referralTablecolor": #848e9c,
    // "progressLine": #848e9c,
    // "ApiBtnClr": #282c34,
    // "QrBg": #000000,
    // "QrText": #f5f5f5,
    // "personalTag": #3c2601,
    // "headerBackground": #0b9eda,
    // "cardBorder": #252930,
    // "headerText": #f5f5f5,
    // "headerInputBorder": #464e5c,
    // "listBorderBottom": #252930,
    // "grayBtnBackground": #282c34,
    // "hideBalButton": #282c34,
    // "scrollbarTrack": #323233,
    // "scrollbarThumb": #5e6673,

    // "OpenHead": #0b9eda,
    // "OrderBtn": #282c34,
    // "OrderBtnColor": #848e9c,
    // "MarketFadeTxt": #d5d4d4,
    // "ResponsiveBordersExchange": #000,
    // "fiatBtn":#282c34,
    // "tabBg":#1E252D,
    // "bgexportbtn":#2c3037,
    // "darkness":#101010,
    // "modalBg" : #282c34,
    "securityBtn": #dfe3ec,
    "darkWhite": #000,
    "darkWhiteReverse": #fff,
    "securitypage": #f2f3f5,
    "securitypage1": #1e252d,
    "twofactorAuthentication1": #1e252d,
    "cmnBtnColor": #0b9eda,
    "grayBtn": #0b9eda,
    "grayBtnAdv": #282c34,
    "lightBtn": #e4e4e4,
    "footerTextCopyright": #0b9eda,
    "yellowBtn": #7D80DF,
    "bgSection": #fafafa,
    "inputborder": #bec3ca,
    "paragraph": #848e9c,
    "titlehead": #1e252d,
    "selectBorder": #bec3ca,
    "selectTxtColor": #0b9eda,
    "selectOptionsBG": #fff,
    "selectTxtColorSelected": #f5f5f5,
    "selectHoverableBg": #f5f5f5,
    "whiteblack": #000,
    "headerDropdownBg": #fafafa,
    "headerDropdownTxt": #1e252d,
    "navigationHoverable": #dfe3ec,
    "twofactorAuthentication": #1e252d,
    "sidebarRightBorder": #dcdcdc,
    "sidebarActiveItem": #f2f3f5,

    "whiteblackBase": #1e252d,
    "logbtnhover": #f2f3f5,
    "ProfileSvgBg": #848e9c,
    "processbar": #f1f2f3,
    "ModalColor": #f5f5f5,
    "tableBG": #fafafa,
    "borderExchangeTopbar": #f0f0f0,
    "exchangeBgClassic": #fafafa,
    "buttonTabExc": #fff,
    "orderFormBg": #f2f3f5,
    "exchangeInput": #f2f3f5,
    "sliderTrack": #474d57,
    "sliderHandle": #fff,
    "sliderRail": #dce0e5,
    "sliderBorder": #474d57,
    "sliderPoints": #fff,
    "sliderPointsColor": #707a8a,
    "sliderPointsBorder": #eaecef,
    "referralScreenBar": #f2f3f5,
    "referralScreenText": #1e252d,
    "referralScreenhistoryBtn": #f3f3f3,
    "referralTablecolor": #848e9c,
    "progressLine": #fafafa,
    "ApiBtnClr": #dfe3ec,
    "QrBg": #f5f5f5,
    "QrText": #0b9eda,
    "personalTag": #f8de753d,
    "headerBackground": #f2f3f5,
    "cardBorder": #dcdcdc,
    "headerText": #1e252d,
    "headerInputBorder": #bec3ca,
    "listBorderBottom": #dcdcdc,
    "grayBtnBackground": #dfe3ec,
    "hideBalButton": #fafafa,

    "scrollbarTrack": #0000000d,
    "scrollbarThumb": #0000001a,

    "OpenHead": #f2f3f5,
    "OrderBtn": #dfe3ec,
    "OrderBtnColor": #000000,
    "MarketFadeTxt": #1e252d,
    "ResponsiveBordersExchange": #efefef,
    "fiatBtn":#DFE3EC,
    "tabBg":#F2F3F5,
    "bgexportbtn":#0b9eda,
    "darkness":#101010,
    "modalBg" : #fafafa,
  ),
);