@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "./Components/Assets/Styles/_mixins.scss";
@import "./Components/Assets/Styles/_vars.scss";

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
div,
button,
input,
optgroup,
select,
textarea,
label,
.ant-btn span {
  font-family: "Roboto", sans-serif !important;
}

// .darkTheme {
//   background-color: #181a20;
// }
.lightTheme,
.darkTheme {
  background-color: #fafafa;
}

.ant-layout {
  @include theme() {
    background-color: theme-get("bgSection");
  }
}

.ant-layout-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: calc(100vh - 110px) !important;

  .flexAuto {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.container {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.container_landing {
  width: 100%;
  max-width: 1368px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

// Common csss----------------------
.orAdd {
  position: relative;
  text-align: center;
  color: $titlehead;
  margin-top: 26px;

  @include theme() {
    color: theme-get("titlehead");
  }

  &::before {
    position: absolute;
    height: 1px;
    width: 100%;
    content: "";
    background-color: $grayLightTxt;
    max-width: 150px;
    // transform: translateY(-50%);
    top: 50%;
    right: 0;
    opacity: 1;

    @media (max-width: 424px) {
      max-width: 125px;
    }
  }

  &::after {
    position: absolute;
    height: 1px;
    width: 100%;
    content: "";
    background-color: $grayLightTxt;
    max-width: 150px;
    // transform: translateY(-50%);
    top: 50%;
    left: 0;
    opacity: 1;

    @media (max-width: 424px) {
      max-width: 125px;
    }
  }
}

// Sidebar table css common_________________________

// .orderCmnTable {
// padding-top: 30px;
.ant-table {
  background-color: transparent;
  // height: 400px;
  overflow: auto;
}

thead {
  position: sticky;
  top: 0;
  z-index: 1;

  tr th {
    @include theme() {
      background-color: theme-get("OpenHead");
    }

    color: $grayLightTxt;
    border-bottom: 0 !important;
    white-space: nowrap;
  }

  @include fluid-type(320px, 1920px, 10px, 14px);

  color: $grayLightTxt;
  border-bottom: 0 !important;
  white-space: nowrap;
}

td {
  background-color: transparent;
  color: $grayLightTxt;
  border-bottom: 0 !important;
  @include fluid-type(320px, 1920px, 10px, 14px);
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}

.ant-empty {
  .ant-empty-description {
    color: $grayLightTxt;
  }
}

// }
.custom-check {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #18a2dc;
    border-color: #18a2dc;
  }
}

// Common csss----------------------End

//sidebar css starts here //

.ant-layout-sider {
  border-right: 1px solid;

  @include theme() {
    background-color: theme-get("bgSection");
    border-color: theme-get("sidebarRightBorder");
  }

  &-trigger {
    border: 1px solid;

    @include theme() {
      background-color: theme-get("bgSection");
      border-color: theme-get("sidebarRightBorder");
      color: theme-get("darkWhite");
    }
  }
}

.ant-menu {
  &-item {
    padding-left: 30px !important;

    &-icon {
      display: flex !important;
      height: 100% !important;
      align-items: center !important;
    }

    margin-top: 0 !important;

    @include theme() {
      color: theme-get("headerDropdownTxt") !important;
    }

    &-selected {
      @include theme() {
        background-color: theme-get("headerBackground") !important;
        color: theme-get("headerDropdownTxt") !important;
      }
    }

    &:hover {
      @include theme() {
        background-color: theme-get("headerBackground") !important;
      }
    }

    &-only-child {
      position: relative;
      padding-left: 80px !important;

      &::before {
        content: "";
        position: absolute;
        left: 60px;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 5px;
        border-radius: 100%;

        @include theme() {
          background-color: theme-get("headerDropdownTxt") !important;
        }
      }
    }
  }

  @include theme() {
    background-color: theme-get("bgSection") !important;
    color: theme-get("headerDropdownTxt") !important;
  }

  &-title-content,
  &-title-content a {
    @include theme() {
      color: theme-get("headerDropdownTxt") !important;
    }
  }

  &-submenu {
    &-arrow {
      &::before,
      &::after {
        @include theme() {
          background-color: theme-get("headerDropdownTxt") !important;
        }
      }
    }

    &-title {
      margin-top: 0 !important;
      padding-left: 30px !important;

      &:hover {
        @include theme() {
          color: theme-get("headerDropdownTxt") !important;
        }
      }
    }

    &:hover {
      @include theme() {
        background-color: theme-get("sidebarActiveItem") !important;
      }
    }

    &-selected {
      @include theme() {
        background-color: theme-get("sidebarActiveItem") !important;
        color: theme-get("headerDropdownTxt") !important;
      }
    }
  }
}

.ant-menu-submenu-popup {
  li {
    padding-left: 30px !important;

    &::before {
      content: unset;
    }
  }
}

.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 calc(50% - 12px) !important;
}

//KYC Modals//

.KycModals {
  .ant-modal-content {
    @include theme() {
      background-color: theme-get("ModalColor");
    }

    padding: 70px 70px 80px;
    border-radius: 5px;
    height: 723px;

    @media (max-width: 991px) {
      padding: 70px 25px 25px;
      height: auto;
    }
  }

  .ant-modal-body {
    padding: 0;
    height: 100%;
  }

  .anticon {
    svg path {
      fill: $grayLightTxt;
    }
  }
}

.hover__link {
  transition: 0.3s all;

  &:hover {
    color: $CommonButton !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

//scrollbar {
::-webkit-scrollbar-track {
  border-radius: 0px;

  @include theme() {
    background-color: theme-get("scrollbarTrack");
  }
}

::-webkit-scrollbar-thumb {
  @include theme() {
    background-color: theme-get("scrollbarThumb");
  }

  border-radius: 3px;
  height: 90px;
}

.scrollBar:hover::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

input:-internal-autofill-selected {
  @include theme() {
    background-color: theme-get("bgSection");
    color: theme-get("whiteblackBase");
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  @include theme() {
    background-color: theme-get("bgSection");
    color: theme-get("whiteblackBase");
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  @include theme() {
    background-color: theme-get("bgSection");
    color: theme-get("whiteblackBase");
    -webkit-text-fill-color: theme-get("whiteblackBase");
  }

  -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
}

// table td,
// th {
//   white-space: nowrap;
// }

.breadcrumb {
  top: 0;
  z-index: 99 !important;

  @include theme() {
    background-color: theme-get("headerBackground");
    color: theme-get("whiteblackBase");
  }

  padding: 18px;
  position: sticky;
  top: 0;
  z-index: 9;
  height: 62px;

  @media (max-width: 991px) {
    padding-left: 60px;
  }
}

.ant-breadcrumb {
  span,
  span > a {
    @include theme() {
      color: theme-get("whiteblackBase");
    }

    font-size: 16px !important;
    font-weight: 500;
  }
}

.ant-menu-inline > .ant-menu-item,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
  @media (min-width: 991px) {
    height: 62px !important;
    line-height: 62px !important;
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset !important;
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background-color: transparent !important;
}

.ant-table {
  // @include theme() {
  //   background-color: theme-get("headerBackground");
  // }
  thead {
    position: sticky;
    top: 0;
    z-index: 9;

    @include theme() {
      background-color: theme-get("headerBackground");
    }
  }

  border-radius: 8px;
  overflow: auto;

  .ant-table-thead > tr > th {
    background-color: transparent;
    padding: 20px 30px;
    // border-bottom: 1px solid !important;

    @include theme() {
      border-color: theme-get("headerInputBorder") !important;
    }

    white-space: nowrap;

    @include theme() {
      color: theme-get("headerDropdownTxt");
    }

    @include fluid-type(320px, 1920px, 14px, 16px);

    &::before {
      content: unset !important;
    }
  }

  td {
    @include fluid-type(320px, 1920px, 14px, 16px);

    @include theme() {
      color: theme-get("headerDropdownTxt");
    }

    font-weight: normal;
  }

  .buttonView {
    border: 0;
    cursor: pointer;
    @include fluid-type(320px, 1920px, 14px, 16px);

    @include theme() {
      background-color: theme-get("cmnBtnColor") !important;
    }

    color: $white !important;
    padding: 8px 15px;
    font-weight: normal;
    border-radius: 8px;
    display: block;
    width: fit-content;
    transition: 0.3s all;

    &:hover {
      opacity: 0.8;
    }
  }

  span > span {
    margin-left: 10px;
  }
}

.tabs {
  &__body {
    margin-left: 0px;
    margin-top: 30px !important;

    .ant-tabs-tab {
      color: $grayLightTxt;
      padding: 10px;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      // @include theme() {
      //   color: theme-get("headerDropdownTxt");
      // }
      color: $white !important;
    }

    .ant-tabs-tab-btn {
      @include theme() {
        color: theme-get("headerDropdownTxt");
      }
    }

    .ant-tabs-ink-bar {
      position: absolute;
      background: $CommonButton;
      pointer-events: none;
      height: 50px !important;
      z-index: -1;

      @include theme() {
        background-color: theme-get("cmnBtnColor");
      }
    }

    .ant-tabs-nav:before {
      @include theme() {
        border-bottom-color: theme-get("sidebarRightBorder");
      }
    }
  }
}

.ant-tabs-tab {
  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    @include theme() {
      color: theme-get("headerDropdownTxt");
    }
  }
}

.deleteBtn {
  border: 0;
  padding: 0;
  background-color: transparent !important;

  &:hover {
    animation: shake 0.82s cubic-bezier(0.26, 0.07, 0.9, 0.9) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .anticon {
    font-size: 24px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

// commontable
.ant-table {
  background-color: $white;
  @include fluid-type(320px, 1920px, 12px, 14px);

  thead {
    // background-color: $fafbfbcolor !important;
    color: $black1;
  }

  .ant-table-thead > tr > th {
    color: $black1;
    @include fluid-type(320px, 1920px, 12px, 14px);
    padding: 12px 20px;
    border-color: $bordercolorcard !important;
    font-weight: normal;

    &:first-child {
      width: 70%;
    }
  }

  .ant-table-tbody > tr > td {
    color: $black1;
    padding: 12px 20px;
    @include fluid-type(320px, 1920px, 12px, 14px);
    border-bottom: 1px solid $bordercolorcard !important;
    background-color: $white;
  }

  .ant-table-row:last-child {
    border: none !important;
  }

  // .timestamp {
  //   border-bottom: 1px solid #1e252d;
  // }

  // .ant-table {
  //   tr {
  //     &:last-child {
  //       td {
  //         border-bottom: 0 !important;
  //         padding: 20px 30px !important;
  //       }
  //     }
  //   }
  // }

  // .lightTheme .ant-table .ant-table-thead > tr > th {
  //     border-color: red !important;
  //   }
}

// common notification time
.timenotification {
  display: flex;
  flex-direction: column !important;

  span {
    color: gray;
  }
}

aside.ant-layout-sider-below {
  z-index: 1000;
  position: absolute;
  height: 100%;
}

span.ant-layout-sider-zero-width-trigger {
  z-index: 1000;
  top: 10px;
  right: -50px;
  background-color: #4da1ff;
}

.card-outer {
  background-color: $white;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
  // box-shadow: 0 2px 4px 0;
  border-radius: 5px;
}

.notifications-RoundBar {
  max-width: 200px;
  margin: auto;

  &_text {
    text-align: center;
    font-size: 14px;
  }
}

.tablesection {
  table {
    min-width: 992px;
    overflow: scroll;
  }

  thead.ant-table-thead {
    background-color: #d4ebf5;
  }

  .ant-table-tbody > tr > td :first-child {
    border: none;
  }
}

.p-20 {
  padding: 30px;
}

.plansData {
  color: grey;
}
.react-tel-input .form-control {
  font-weight: 400;
}
.ant-btn {
  span {
    font-weight: 400;
  }
}
.ant-btn-primary {
  span {
    color: #fff;
  }
}

.show {
  p,
  svg,
  path {
    color: #fff;
  }
}
.copyKeybtn {
  p {
    color: #fff;
  }
}
